<template>
<div style="text-align: left">
  <Card style="height:100%">
    <div class="search-con search-con-top">
      <Input clearable placeholder="输入通知名 搜索" class="search-input" v-model="queryinfo.name"
             @on-enter="namechange"
             @on-change="namechange"/>
      <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      <slot name="new_btn">
        <Button type="primary" class="search-btn"  @click="showaddmodel">添加</Button>
      </slot>
    </div>
  <div>
    <Table border ref="selection" :columns="columns" :data="data" ></Table>
  </div>
  <template>
    <Page :current="page"
          :total="total"
          :page-size-opts="pagesizeopts"
          @on-change="changepage"
          @on-page-size-change="changepagesize"
          show-total
          show-sizer
          style="text-align: right;" />
  </template>

  <Drawer
      title="修改playbook剧本"
      v-model="updatedrawer"
      width="720"
      :mask-closable="false"
      :styles="styles"
  >
    <Form ref="updateplaybookref" :model="updateplaybookData" :rules="ruleInline">
      <FormItem label="名称" prop="name">
        <Input v-model="updateplaybookData.name" placeholder="请输入playbook名称"></Input>
      </FormItem>
      <FormItem label="内容" prop="content">
<!--        <Input v-model="updateplaybookData.content" type="textarea" placeholder="请输入playbook内容" :autosize="{minRows: 10}"></Input>-->
        <div class="editor-container">
          <yaml-editor  v-model="updateplaybookData.content" />
        </div>
      </FormItem>
      <FormItem label="描述" prop="details">
        <Input v-model="updateplaybookData.details" type="textarea" placeholder="请输入playbook描述" :autosize="{minRows: 10}"></Input>
      </FormItem>
    </Form>
    <div class="demo-drawer-footer">
      <Button style="margin-right: 8px" @click="updatedrawer = false">关闭</Button>
      <Button type="primary" @click="submit">保存</Button>
    </div>
  </Drawer>
  <Modal
      v-model="showdelete"
      title="删除playbook剧本"
      @on-ok="deletepalybook(delplaybook.id)"
      @on-cancel="canceldelete">
    <p>playbook 名称： {{delplaybook.name}}</p>
  </Modal>

  </Card>
</div>
</template>

<script>
  import moment from 'moment';
  import YamlEditor from '@/components/YamlEditor/index.vue';
  // import add_playbook from '@/View/opsTools/AnsibleTools/AddPlaybook.vue';
  import {get,del} from "@/api/http.js";
  export default {
  name: "Index",
  components: { YamlEditor },
  data () {
    return {
      queryinfo: {
        page: 1,
        pagesize: 10,
        name:"",
      },

      playbookdata: {
        content:""
      },
      updateplaybookData: {
        content:""
      },
      delplaybook: {},
      showdelete: false,
      updatedrawer: false,
      page: 1,
      pagesizeopts: [10, 20, 30, 40],
      pagesize: 10,
      total: 0,
      selectedPlaybooks: [],
      ruleplaybookdata: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      },
      ruleInline: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      },
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '名称',
          key: 'name'
        },
        {
          title: '描述',
          key: 'details'
        },
        {
          title: '创建者',
          key: 'created_user'
        },
        {
          title: '创建时间',
          key: 'created_at',
          render: (h,{row}) =>{
            return h('div',this.time_change(row.created_at))
          }
        },
        {
          title: '更新时间',
          key: 'updated_at',
          render: (h,{row}) =>{
            return h('div',this.time_change(row.updated_at))
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 |'),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }
      ],
      data: [],
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
    }
  },
  methods: {
    time_change(time_str) {
      let res = moment(time_str).format('YYYY-MM-DD HH:mm:ss')
      return res
    },

    getdata(queryinfo) {
      const url = "goansible/api/v1/ansible/playbook"
      get(url, queryinfo)
          .then((resp)=>{
            this.data = resp.data;
            this.total = resp.count
          }).catch(e=>{
        return e
      })
    },

    submit () {
      this.$refs.updateplaybookref.validate((valid) => {
        if (valid) {
          const id = this.updateplaybookData.id
          const data = {
            "name":this.updateplaybookData.name,
            "content":this.updateplaybookData.content,
            "details":this.updateplaybookData.details}
          this.updateplaybook(id,data)
        }
      })
    },
    namechange() {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getdata(this.queryinfo)
    },

    changepage (page) {
      this.queryinfo.page = page
      this.getdata(this.queryinfo)
    },

    changepagesize(pagesize) {
      this.queryinfo.pagesize = pagesize
      this.getdata(this.queryinfo)
    },

    deletepalybook(id) {
      del(`/goansible/api/v1/ansible/playbook?id=`+ id)
          .then((resp)=>{
            this.getPlaybook()
          }).catch(e=>{
        return e
      })
    },

    canceldelete () {},

    showupdatedrawer(row) {
      console.log(row.id)
      const url = `/opsTools/AnsibleTools/updateplaybook/` + row.id
      this.$router.push(url)
    },

    cancelshowaddmodel () {
      this.$Message.info('Clicked cancel');
    },
    isdelete (row) {
      this.delplaybook = row
      this.showdelete = true
    },

    showaddmodel () {
      this.$router.push('/opsTools/AnsibleTools/addplaybook')
    },

    deleteplaybook() {}
  },
  created() {
    this.time_change()
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
